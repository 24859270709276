import { useState } from 'react'
import { Link, NavLink, useLoaderData } from '@ubo/losse-sjedel'
import { motion } from 'framer-motion'
import type { HeaderLoaderData } from './Header'
import clsx from 'clsx'

export default function HeaderMenu({ setMegamenu }: { setMegamenu: (bool: boolean) => void }) {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [open, setOpen] = useState(-1)
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768

  return (
    <>
      {header?.menuHeader?.map((l, index: number) => (
        <motion.li
          onHoverStart={() => {
            if (!isMobile) {
              setOpen(index)
              if (index === 0) {
                setMegamenu(true)
              }
            }
          }}
          // onHoverEnd={() => {
          //   setOpen(-1)
          //   if (index === 0) {
          //     setMegamenu(false)
          //   }
          // }}
          className="relative 2xl:px-6 px-3 -my-8 py-8"
          key={index}
        >
          <div className="flex flex-wrap items-center">
            {index === 0 ? (
              <Link
                onClick={(e) => {
                  e.preventDefault()
                  setMegamenu(true)
                }}
                className={clsx(
                  'text-white 2xl:text-lg lg:text-base text-xl relative menu-item top-[1px]',
                  'cursor-default before:!hidden after:!hidden flex items-center gap-x-2'
                )}
                to={l?.link?.url || '/'}
              >
                {l?.link?.title}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="feather feather-chevron-down"
                  viewBox="0 0 24 24"
                >
                  <path d="M6 9L12 15 18 9"></path>
                </svg>
              </Link>
            ) : (
              <NavLink
                onClick={
                  index === 0
                    ? (e) => {
                      e.preventDefault()
                      setMegamenu(true)
                    }
                    : undefined
                }
                className={'text-white 2xl:text-lg lg:text-base text-xl relative menu-item top-[1px]'}
                to={l?.link?.url || '/'}
              >
                {l?.link?.title}
              </NavLink>
            )}

            {l?.submenu && (
              <div onClick={open === index ? () => setOpen(-1) : () => setOpen(index)} className="ml-[15px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={
                    open === index
                      ? {
                        transform: 'rotate(180deg)'
                      }
                      : {
                        transform: 'rotate(0deg)'
                      }
                  }
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#1D3660"
                  strokeWidth={1}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="transition-all"
                >
                  <polyline points="6 9 12 15 18 9" />
                </svg>
              </div>
            )}
          </div>
        </motion.li>
      ))}
    </>
  )
}
