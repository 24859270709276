import type { IconProps } from './props'

export default function Instagram(props: IconProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="#373738"
        d="M10.197 20h-.199a69.502 69.502 0 01-4.425-.122c-1.292-.079-2.47-.525-3.41-1.29-.906-.74-1.524-1.739-1.839-2.97-.273-1.071-.288-2.123-.301-3.141-.01-.73-.02-1.595-.023-2.475.002-.884.013-1.749.023-2.479.013-1.017.028-2.07.301-3.141.315-1.232.933-2.23 1.84-2.97C3.102.648 4.28.202 5.573.123A69.685 69.685 0 0110.001 0a69.972 69.972 0 014.425.122c1.292.079 2.47.525 3.41 1.29.906.74 1.524 1.738 1.839 2.97.273 1.071.288 2.124.301 3.141.01.73.021 1.595.023 2.475v.004c-.002.88-.012 1.745-.023 2.475-.014 1.017-.028 2.07-.301 3.141-.315 1.231-.933 2.23-1.84 2.97-.938.765-2.117 1.211-3.409 1.29-1.35.082-2.735.122-4.23.122zm-.199-1.563a68.192 68.192 0 004.334-.119c.977-.059 1.823-.376 2.517-.942.641-.523 1.083-1.244 1.313-2.144.227-.892.24-1.85.253-2.776.01-.726.02-1.585.022-2.456a219.66 219.66 0 00-.022-2.456c-.013-.926-.026-1.884-.253-2.776-.23-.9-.672-1.622-1.313-2.145-.694-.565-1.54-.882-2.517-.941a68.273 68.273 0 00-4.33-.12 68.092 68.092 0 00-4.334.12c-.977.059-1.823.376-2.517.941-.641.523-1.083 1.245-1.313 2.145-.227.892-.24 1.85-.253 2.776-.01.726-.02 1.586-.022 2.458.002.868.012 1.728.022 2.454.013.926.026 1.884.253 2.776.23.9.672 1.622 1.313 2.144.694.566 1.54.883 2.517.942 1.376.084 2.792.123 4.33.12zm-.037-3.554A4.889 4.889 0 015.078 10 4.889 4.889 0 019.96 5.117 4.888 4.888 0 0114.844 10a4.888 4.888 0 01-4.883 4.883zm0-8.203C8.13 6.68 6.64 8.17 6.64 10c0 1.83 1.489 3.32 3.32 3.32 1.83 0 3.32-1.49 3.32-3.32 0-1.83-1.49-3.32-3.32-3.32zm5.43-3.125a1.172 1.172 0 100 2.344 1.172 1.172 0 000-2.344z"
        opacity="0.5"
      ></path>
    </svg>
  )
}
