import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import type { LoaderData } from '@ubo/losse-sjedel'
import { useLoaderData } from '@ubo/losse-sjedel'
import S from '~/components/elements/Icons/S'

export default function BannerNoImage({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const post = useLoaderData<LoaderData>()
  return (
    <section className="bg-dark pt-44 text-white sm:mb-20 mb-10" data-component="BannerNoImage">
      <div className="container relative">
        <Breadcrumbs className="lg:mb-24 sm:mb-14 mb-8" title={post.page.title as string} />
        <Content className="sm:pb-16 pb-10">{fields.description}</Content>

        <S className="absolute right-0 top-0 h-full w-auto" />
      </div>
    </section>
  )
}
