import { LosseFlex } from '@ubo/losse-sjedel'
import type { Page_Flexcontent } from '~/graphql/types'

// Flex components
import BannerShell from './Banner/BannerShell'
import TextShell from './Text/TextShell'
import Fragment from './Fragment'
import FormShell from './Form/FormShell'
import PostsShell from './Posts/PostsShell'
import Assortiment from './Assortiment'
import InstagramFeed from './InstagramFeed'
import Gallery from './Gallery'
import Accordeons from './Accordeons'
import Team from './Team'

interface FlexContentHandlerProps {
  flex: Page_Flexcontent['flex']
  prefix: string
}

export default function FlexContentHandler({ flex, prefix = 'page_Flexcontent' }: FlexContentHandlerProps) {
  const flexers = {
    [`${prefix}_Flex_Banner`]: BannerShell,
    [`${prefix}_Flex_Assortiment`]: Assortiment,
    [`${prefix}_Flex_Text`]: TextShell,
    [`${prefix}_Flex_Posts`]: PostsShell,
    [`${prefix}_Flex_Form`]: FormShell,
    [`${prefix}_Flex_InstagramFeed`]: InstagramFeed,
    [`${prefix}_Flex_Gallery`]: Gallery,
    [`${prefix}_Flex_Accordeons`]: Accordeons,
    [`${prefix}_Flex_Team`]: Team,
    [`${prefix}_Flex_Fragment`]: Fragment
  }

  // @ts-ignore
  return <LosseFlex flex={flex} flexers={flexers} />
}
