import clsx from 'clsx'
import { motion, AnimatePresence } from 'framer-motion'
import { useState } from 'react'
import type { Maybe, Page_Flexcontent_Flex_Accordeons, Page_Flexcontent_Flex_Accordeons_List } from '~/graphql/types'
import Content from '../elements/Content'

export default function Accordeons({ fields }: { fields: Page_Flexcontent_Flex_Accordeons }) {
  return (
    <section data-component="Accordeons" className={clsx(fields?.theme === 'dark' && 'bg-dark', 'sm:mb-20 mb-10')}>
      <div className="container mx-auto xl:max-w-screen-xl py-10 sm:py-20">
        <Content className={clsx(fields?.theme === 'dark' ? 'children-h2:text-white/30' : 'children-h2:text-dark/30', 'sm:mb-8')}>
          {fields.title}
        </Content>

        <div className="max-w-screen-md mx-auto">
          {fields.list?.map((item, index) => {
            if (!item) return null
            return (
              <Accordeon
                theme={fields?.theme}
                isFirst={index === 0}
                isLast={index === (fields.list?.length || 0) - 1}
                key={item.title}
                item={item}
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}

function Accordeon({
  theme,
  item,
  isFirst,
  isLast
}: {
  theme?: Maybe<string> | undefined
  item: Page_Flexcontent_Flex_Accordeons_List
  isFirst: boolean
  isLast: boolean
}) {
  const [isOpen, setIsOpen] = useState(isFirst)

  return (
    <div className={clsx(theme === 'dark' ? 'border-white/20' : 'border-black/20', 'pt-5 pb-5', !isLast && 'border-b')}>
      <div className="flex items-center cursor-pointer select-none" onClick={() => setIsOpen(!isOpen)}>
        <span
          className={clsx(
            theme === 'dark' ? 'text-white/30' : 'text-dark/30',
            'sm:text-6xl text-4xl font-bold sm:w-16 w-12 flex-none text-left'
          )}
        >
          {item?.listpoint}
        </span>
        <h3 className={clsx(theme === 'dark' ? 'text-white' : 'text-black', 'sm:text-3xl text-xl mb-0 flex-none font-bold')}>
          {item?.title}
        </h3>

        {isFirst && <hr className={clsx(theme === 'dark' ? 'border-white/20' : 'border-black/20', 'sm:block hidden w-full mx-10')} />}

        {isOpen ? (
          <svg
            className={clsx(theme === 'dark' ? 'text-white' : 'text-dark', 'ml-auto flex-none sm:w-8 w-6')}
            width="39"
            height="3"
            viewBox="0 0 39 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.375 0H1.625C0.727537 0 0 0.671573 0 1.5C0 2.32843 0.727537 3 1.625 3H37.375C38.2725 3 39 2.32843 39 1.5C39 0.671573 38.2725 0 37.375 0Z"
              fill="currentColor"
            />
          </svg>
        ) : (
          <svg
            className={clsx(theme === 'dark' ? 'text-white' : 'text-dark', 'ml-auto flex-none sm:w-8 w-6')}
            xmlns="http://www.w3.org/2000/svg"
            width="39"
            height="39"
            fill="none"
            viewBox="0 0 39 39"
          >
            <g clipPath="url(#clip0_688_706)">
              <path
                fill="currentColor"
                d="M37.375 17.875h-16.25V1.625A1.625 1.625 0 0019.5 0a1.625 1.625 0 00-1.625 1.625v16.25H1.625A1.625 1.625 0 000 19.5a1.625 1.625 0 001.625 1.625h16.25v16.25a1.625 1.625 0 103.25 0v-16.25h16.25a1.625 1.625 0 100-3.25z"
              ></path>
            </g>
          </svg>
        )}
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div className="overflow-hidden" initial={{ height: 0 }} animate={{ height: 'fit-content' }} exit={{ height: 0 }}>
            <Content className={clsx(theme === 'dark' ? 'text-white' : 'text-black', 'sm:pl-16 pl-12 py-5 sm:text-base text-sm')}>
              {item.description}
            </Content>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
