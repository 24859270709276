import type { IconProps } from './props'

export default function ArrowRight(props: IconProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="13" fill="none" viewBox="0 0 24 13">
      <path
        fill="#fff"
        d="M23.12 4.206L19.25.296a1 1 0 10-1.42 1.41l3.56 3.59H1a1 1 0 000 2h20.45l-3.62 3.61a1.002 1.002 0 00.325 1.639 1 1 0 001.095-.22l3.87-3.88a3 3 0 000-4.24z"
      ></path>
    </svg>
  )
}
