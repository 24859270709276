import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import BannerDefault from './BannerDefault'
import BannerHome from './BannerHome'
import BannerQuote from './BannerQuote'
import BannerTwoColumns from './BannerTwoColumns'
import BannerNoImage from './BannerNoImage'
import BannerBenefits from './BannerBenefits'

export default function BannerShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: BannerDefault,
    home: BannerHome,
    quote: BannerQuote,
    two_columns: BannerTwoColumns,
    without_image: BannerNoImage,
    benefits: BannerBenefits
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
