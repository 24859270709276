import { losseLoader, useFetcher } from '@ubo/losse-sjedel'
import { useEffect, useRef } from 'react'
import { Autoplay, Navigation } from 'swiper'
import type { SwiperRef } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import Content from '~/components/elements/Content'
import type { GooglePlace, Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextReviews({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const fetcher = useFetcher<{ googlePlace: GooglePlace }>()
  const sliderRef = useRef<SwiperRef>(null)

  useEffect(() => {
    const loader = losseLoader({
      name: 'GooglePlace'
    })
    fetcher.load(loader)
  }, [])

  const rating = fetcher.data?.googlePlace.totalRating as number

  const firstStarPercentage = rating >= 1 ? 100 : rating * 100
  const secondStarPercentage = rating >= 2 ? 100 : (rating - 1) * 100
  const thirdStarPercentage = rating >= 3 ? 100 : (rating - 2) * 100
  const fourthStarPercentage = rating >= 4 ? 100 : (rating - 3) * 100
  const fifthStarPercentage = rating >= 5 ? 100 : (rating - 4) * 100

  return (
    <section className="container xl:max-w-screen-xl sm:mb-20 mb-10" data-component="TextReviews">
      <Content className="children-h2:text-dark/30 sm:mb-4">{fields.title}</Content>

      <div className="flex items-center flex-wrap mb-6 gap-x-4">
        <span className="sm:with-line font-bold text-lg">{fetcher.data?.googlePlace.totalRating?.toFixed(1)}</span>

        <div className="relative">
          <svg width="135" height="28">
            <defs>
              <linearGradient id="ubo-stars__reviews_footer_star-1">
                <stop offset="0%" stopColor="#F9BF2A"></stop>
                <stop offset={`${firstStarPercentage}%`} stopColor="#F9BF2A"></stop>
                <stop offset="0%" stopColor="#C4C4C4"></stop>
                <stop offset="100%" stopColor="#C4C4C4"></stop>
              </linearGradient>
              <linearGradient id="ubo-stars__reviews_footer_star-2">
                <stop offset="0%" stopColor="#F9BF2A"></stop>
                <stop offset={`${secondStarPercentage}%`} stopColor="#F9BF2A"></stop>
                <stop offset="0%" stopColor="#C4C4C4"></stop>
                <stop offset="100%" stopColor="#C4C4C4"></stop>
              </linearGradient>
              <linearGradient id="ubo-stars__reviews_footer_star-3">
                <stop offset="0%" stopColor="#F9BF2A"></stop>
                <stop offset={`${thirdStarPercentage}%`} stopColor="#F9BF2A"></stop>
                <stop offset="0%" stopColor="#C4C4C4"></stop>
                <stop offset="100%" stopColor="#C4C4C4"></stop>
              </linearGradient>
              <linearGradient id="ubo-stars__reviews_footer_star-4">
                <stop offset="0%" stopColor="#F9BF2A"></stop>
                <stop offset={`${fourthStarPercentage}%`} stopColor="#F9BF2A"></stop>
                <stop offset="0%" stopColor="#C4C4C4"></stop>
                <stop offset="100%" stopColor="#C4C4C4"></stop>
              </linearGradient>
              <linearGradient id="ubo-stars__reviews_footer_star-5">
                <stop offset="0%" stopColor="#F9BF2A"></stop>
                <stop offset={`${fifthStarPercentage}%`} stopColor="#F9BF2A"></stop>
                <stop offset="0%" stopColor="#C4C4C4"></stop>
                <stop offset="100%" stopColor="#C4C4C4"></stop>
              </linearGradient>
            </defs>
            <path fill="url(#ubo-stars__reviews_footer_star-1)" d="M13.8 1.25l-8.28 22.5L26.22 9.4H1.38l20.7 14.35-8.28-22.5z"></path>
            <path fill="url(#ubo-stars__reviews_footer_star-2)" d="M41.4 1.25l-8.28 22.5L53.82 9.4H28.98l20.7 14.35-8.28-22.5z"></path>
            <path fill="url(#ubo-stars__reviews_footer_star-3)" d="M69 1.25l-8.28 22.5L81.42 9.4H56.58l20.7 14.35L69 1.25z"></path>
            <path fill="url(#ubo-stars__reviews_footer_star-4)" d="M96.6 1.25l-8.28 22.5 20.7-14.35H84.18l20.7 14.35-8.28-22.5z"></path>
            <path fill="url(#ubo-stars__reviews_footer_star-5)" d="M124.2 1.25l-8.28 22.5 20.7-14.35h-24.84l20.7 14.35-8.28-22.5z"></path>
          </svg>
        </div>
        <div>
          <span>{fetcher.data?.googlePlace.totalReviews} Google reviews</span>
        </div>
      </div>

      <div className="relative">
        <div className="absolute top-0 bottom-0 flex items-center justify-between w-full my-auto"></div>

        <Swiper ref={sliderRef} modules={[Navigation, Autoplay]} loop autoplay={{ delay: 5000 }} className="max-w-screen-lg mx-auto">
          {fetcher.data?.googlePlace?.reviews
            ?.filter((review) => review?.text)
            .map((review) => (
              <SwiperSlide key={review?.authorName}>
                <div className="md:px-20 px-12 md:py-8 py-6 md:m-8 sm:m-6 m-4 bg-white thick-shadow text-center relative">
                  <button
                    // @ts-ignore
                    onClick={() => sliderRef.current.swiper.slidePrev()}
                    className="absolute left-5 top-0 bottom-0 my-auto text-xl font-bold w-4 h-4"
                    type="button"
                  >
                    {'<'}
                  </button>
                  <button
                    // @ts-ignore
                    onClick={() => sliderRef.current.swiper.slideNext()}
                    className="absolute right-5 top-0 bottom-0 my-auto text-xl font-bold w-4 h-4"
                    type="button"
                  >
                    {'>'}
                  </button>

                  <Content className="text-center mb-6 sm:text-base text-sm">{review?.text}</Content>
                  <strong>{review?.authorName}</strong>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </section>
  )
}
