import { LosseLink } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Posts, Project } from '~/graphql/types'
import ProjectItem from './item/ProjectItem'

export default function LatestProjects({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section className="container sm:mb-20 mb-10" data-component="LatestProjects">
      <div className="flex sm:flex-row flex-col sm:items-center sm:mb-8 mb-6">
        <Content>{fields.title}</Content>

        <LosseLink className="with-line !font-normal ml-auto lg:text-xl sm:text-lg text-base" src={fields.readmoretext} />
      </div>

      <div className="grid sm:grid-cols-3 grid-cols-2 gap-4">
        {fields.latestPosts?.edges?.slice(0, 6).map((post) => {
          const project = post?.node as Project

          return <ProjectItem project={project} key={project?.databaseId} />
        })}
      </div>
    </section>
  )
}
