import { LossePlaatjie } from '@ubo/losse-sjedel'
import { FreeMode, Mousewheel, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { Page_Flexcontent_Flex_Team, Teammember } from '~/graphql/types'
import Content from '../elements/Content'

export default function Team({ fields }: { fields: Page_Flexcontent_Flex_Team }) {
  return (
    <section data-component="Team" className="sm:mb-20 mb-10">
      <div className="sm:mb-14 mb-8 sm:pb-24 pb-10 container xl:max-w-screen-xl">
        <Content>{fields.title}</Content>
        <span className="with-line !font-normal">{fields.subtitle}</span>
      </div>

      <div className="bg-dark">
        <div className="container xl:max-w-screen-xl mx-auto relative sm:bottom-24 bottom-10">
          <Swiper
            modules={[FreeMode, Mousewheel, Navigation]}
            freeMode
            mousewheel={true}
            slidesPerView={2.5}
            navigation
            breakpoints={{
              992: {
                slidesPerView: 3.5
              }
            }}
            spaceBetween={15}
          >
            {fields.team?.nodes?.map((item) => {
              const team = item as Teammember

              return (
                <SwiperSlide className="relative" key={team?.id}>
                  <LossePlaatjie loading="eager" maxWidth={600} src={team.fields?.photo} />

                  <div
                    className="absolute bottom-0 left-0 w-full sm:h-64 h-16 select-none"
                    style={{ background: 'linear-gradient(0deg, #373738 0%, rgba(55, 55, 56, 0) 100%)' }}
                  ></div>

                  <div className="absolute left-0 bottom-0 w-full text-white sm:px-8 px-4 py-6">
                    <h3 className="font-bold lg:text-2xl sm:text-xl mb-4">{team.title}</h3>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </section>
  )
}
