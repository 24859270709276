import Content from '~/components/elements/Content'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerTwoColumns({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section className="sm:mb-20 mb-10" data-component="BannerTwoColumns">
      <div className="container">
        <div className="grid sm:grid-cols-2">
          <div>
            <LossePlaatjie className="w-full h-full object-cover" src={fields.image} maxWidth={800} />
          </div>
          <div className="bg-gray text-white xl:px-16 sm:px-10 px-6 xl:py-12 sm:py-8 py-6 flex flex-col justify-center items-start">
            <Content className="lg:mb-11 mb-6 lg:children-h2:mb-10 xl:text-lg">{fields.description}</Content>
            <LosseLink className="button-transparent" src={fields.callToAction} />
          </div>
        </div>
      </div>
    </section>
  )
}
