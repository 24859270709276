import Content from '~/components/elements/Content'
import type { LoaderData } from '@ubo/losse-sjedel'
import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import S from '~/components/elements/Icons/S'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const { page } = useLoaderData<LoaderData>()
  return (
    <section className="md:bg-dark text-white sm:mb-20 mb-10 relative" data-component="BannerDefault">
      <div className="container">
        <div className="md:grid xl:grid-cols-3 md:grid-cols-2">
          <div className="relative pt-44 z-20">
            <Breadcrumbs className="lg:mb-40 md:mb-24 mb-14" title={page.title || ''} />

            <Content className="lg:mb-40 md:mb-24 mb-14">{fields.description}</Content>
            <S className="absolute md:block hidden right-0 bottom-0 lg:w-[375px] w-[300px] h-auto" />
          </div>
          <div className="md:relative xl:col-span-2">
            <div className="bg-black/50 md:hidden block w-full h-full absolute left-0 top-0 z-10" />
            <LossePlaatjie
              maxWidth={2000}
              className="object-cover xl:w-[65vw] md:w-[50vw] w-[100vw] h-full absolute left-0 top-0 -mr-40 max-w-none"
              src={fields.image}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
