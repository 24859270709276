import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerBenefits({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section className="sm:mb-20 mb-10 overflow-hidden" data-component="BannerBenefits">
      <div className="container">
        <div className="lg:grid flex flex-col grid-cols-4 items-start gap-y-8">
          <div className="lg:-mr-36 lg:w-auto w-full lg:max-w-none max-w-[400px]">
            {fields.useGoogleMaps ? (
              <iframe
                style={{ border: 0 }}
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDrpJPm8dkTQa3Icmy4jH8QLtv_rPrmAOs
                &q=Stuimer Metalworks"
                title="Werkplaats"
                className="lg:mt-36 z-10 relative w-full lg:h-[679px] md:h-[400px] h-[300px]"
              />
            ) : (
              <LossePlaatjie className="lg:mt-36 z-10 relative w-full" src={fields.image} />
            )}
          </div>

          <div className="bg-dark 2xl:px-64 xl:px-52 lg:pl-52 sm:pl-12 pl-6 lg:pr-0 sm:pr-12 pr-6 xl:pt-14 sm:pt-10 pt-6 xl:pb-20 sm:pb-10 pb-6 lg:col-span-3 relative">
            <div className="bg-dark absolute left-full top-0 w-[100vw] h-full lg:block hidden" />

            <Content className="ul-with-square children-h2:text-white/40 children-h2:mb-10 text-white mb-10 sm:children-table:ml-12 children-table:ml-0">
              {fields.description}
            </Content>

            <LosseLink className="with-line text-white" src={fields.callToAction} />
          </div>
        </div>
      </div>
    </section>
  )
}
