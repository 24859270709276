import Content from '~/components/elements/Content'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Quote from '~/components/elements/Icons/Quote'
import { motion } from 'framer-motion'

export default function BannerQuote({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section className="sm:mb-20 mb-10" data-component="BannerQuote">
      <div className="container">
        <div className="relative">
          <motion.div
            initial={{ opacity: 0 }}
            exit={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            transition={{ repeat: Infinity, repeatType: 'reverse', duration: 2, repeatDelay: 3 }}
          >
            <LossePlaatjie maxWidth={2000} className="object-cover absolute left-0 top-0 w-full h-full" src={fields.imageFade} />
          </motion.div>
          <LossePlaatjie maxWidth={2000} className="object-cover" src={fields.image} />
        </div>

        <div className="xl:grid xl:grid-cols-2 2xl:max-w-screen-xl xl:max-w-screen-lg lg:max-w-screen-md max-w-screen-sm mx-auto">
          <div className="sm:py-10 py-6 sm:px-14 px-8 bg-gray text-white xl:mx-auto lg:-mt-32 sm:-mt-16 -mt-8 z-10 relative xl:w-full lg:w-[60%] w-[79%] sm:ml-0 mx-auto">
            <Quote className="absolute sm:left-8 left-4 top-0 sm:w-[123px] w-[80px] h-auto" />
            <Content className="lg:text-4xl sm:text-3xl text-lg font-bold">{fields?.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
