import Content from '~/components/elements/Content'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerHome({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section className="h-screen relative overflow-hidden" data-component="BannerHome">
      <LossePlaatjie loading="eager" maxWidth={2000} className="absolute left-0 top-0 w-full h-full z-10 object-cover" src={fields.image} />
      <div className="container z-10 relative h-full">
        <div className="sm:bg-black/50 2xl:w-[40%] xl:w-[50%] lg:w-[55%] sm:w-[60%] w-full relative h-full text-white flex flex-col justify-center items-start">
          <div className="bg-black/50 absolute -left-[50vw] top-0 h-full sm:w-[50vw] w-[210%]" />
          <Content className="lg:children-h2:text-2xl relative children-h2:text-xl children-h2:font-normal children-h2:mb-0 text-shadow lg:children-ul:text-xl sm:children-ul:text-base children-ul:text-s sm:children-ul:pl-4 children-ul:pl-0 mb-8 lg:children-h1:text-7xl sm:children-h1:text-5xl children-h1:text-5xl">
            {fields?.description}
          </Content>
          <LosseLink src={fields.callToAction} className="button relative" />
        </div>
      </div>
    </section>
  )
}
