import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import LatestProjects from './LatestProjects'
import Projects from './Projects'
import PostsHighlighted from './HighlightedProjects'
import Vacancies from './Vacancies'

export default function PostsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    latest_projects: LatestProjects,
    projects: Projects,
    highlighted_projects: PostsHighlighted,
    vacancies: Vacancies
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
