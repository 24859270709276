import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextThreeColumns({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section data-component="TextThreeColumns" className="bg-dark text-white xl:pt-20 pt-10 xl:pb-24 pb-16 sm:mb-20 mb-10">
      <div className="container">
        <Content className="children-h2:text-white/30 mb-14">{fields.title}</Content>

        <div className="grid sm:grid-cols-3 grid-cols-2 just md:gap-x-6 gap-x-3 gap-y-6">
          {fields.threeColumns?.map((column) => (
            <div className="flex flex-col items-center text-center sm:last:col-span-1 last:col-span-2" key={column?.title}>
              <LossePlaatjie className="lg:h-[205px] md:h-[150px] h-[125px] object-contain" src={column?.icon} />
              <Content className="mt-8 mb-2 font-bold uppercase lg:text-2xl md:text-lg sm:text-base text-sm">{column?.title}</Content>
              <Content className="xl:text-lg md:text-base text-sm">{column?.description}</Content>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
