import type { IconProps } from './props'

export default function Quote(props: IconProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="123" height="94" fill="none" viewBox="0 0 123 94">
      <path
        fill="#fff"
        d="M0 94h51.926V43.434h-20.77L56.146 0H37.97L15.578 25.283C4.219 38.248 0 46.352 0 58.993V94zm66.855 0h51.926V43.434h-20.77L123 0h-18.174L82.433 25.283c-11.36 12.965-15.578 21.069-15.578 33.71V94z"
        opacity="0.1"
      ></path>
    </svg>
  )
}
