import clsx from 'clsx'
import { useState } from 'react'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet-async'

export default function TextFaq({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const [selected, setSelected] = useState(-1)

  function mapFaq() {
    let response = ''

    fields?.faq?.forEach((item, index) => {
      response += `${index > 0 ? ',' : ''}{
        "@type": "Question",
        "name": "${item?.question}",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "${item?.answer}"
        }
      }`
    })

    return response
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`{
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [${mapFaq()}]
    }`}
        </script>
      </Helmet>
      <section className="sm:mb-32 mb-10 mt-10" data-component="TextFaq">
        <div className="container max-w-2xl">
          <Content className="children-h2:text-dark/30 mb-8 text-center w-full">{fields.title}</Content>

          <Content className="mt-2 sm:mt-10 children-h3:text-xl sm:children-h3:text-2xl text-center w-full">{fields.description}</Content>

          <div className="mt-10 sm:mt-20">
            {fields?.faq?.map((item, index) => {
              const isSelected = selected === index

              return (
                <div
                  key={index}
                  className="border-b border-black mb-6"
                  itemScope
                  itemProp="mainEntity"
                  itemType="https://schema.org/Question"
                >
                  <button
                    type="button"
                    className="flex items-center justify-between py-2 w-full px-2"
                    onClick={() => {
                      setSelected(isSelected ? -1 : index)
                    }}
                  >
                    <h3 className="font-bold text-xl text-left" itemProp="name">
                      {item?.question}
                    </h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      className={clsx(isSelected && 'rotate-90', 'transition-all')}
                    >
                      <path
                        d="M8.20986 5.29018C8.11613 5.38315 8.04174 5.49375 7.99097 5.61561C7.9402 5.73746 7.91406 5.86817 7.91406 6.00018C7.91406 6.13219 7.9402 6.2629 7.99097 6.38476C8.04174 6.50662 8.11613 6.61722 8.20986 6.71018L12.7899 11.2902C12.8836 11.3831 12.958 11.4937 13.0087 11.6156C13.0595 11.7375 13.0856 11.8682 13.0856 12.0002C13.0856 12.1322 13.0595 12.2629 13.0087 12.3848C12.958 12.5066 12.8836 12.6172 12.7899 12.7102L8.20986 17.2902C8.11613 17.3831 8.04174 17.4937 7.99097 17.6156C7.9402 17.7375 7.91406 17.8682 7.91406 18.0002C7.91406 18.1322 7.9402 18.2629 7.99097 18.3848C8.04174 18.5066 8.11613 18.6172 8.20986 18.7102C8.39722 18.8964 8.65067 19.001 8.91486 19.001C9.17904 19.001 9.4325 18.8964 9.61986 18.7102L14.2098 14.1202C14.7716 13.5577 15.0872 12.7952 15.0872 12.0002C15.0872 11.2052 14.7716 10.4427 14.2098 9.88018L9.61986 5.29018C9.43249 5.10393 9.17904 4.99939 8.91486 4.99939C8.65067 4.99939 8.39722 5.10393 8.20986 5.29018Z"
                        fill="#1D1D1B"
                      />
                    </svg>
                  </button>
                  <motion.div
                    className="overflow-hidden h-[0px]"
                    animate={isSelected ? 'open' : 'closed'}
                    variants={{
                      open: {
                        height: 'auto'
                      },
                      closed: {
                        height: '0px'
                      }
                    }}
                    itemScope
                    itemProp="acceptedAnswer"
                    itemType="https://schema.org/Answer"
                  >
                    <Content className="px-2 pt-2 pb-4" itemProp="text">
                      {item?.answer}
                    </Content>
                  </motion.div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </>
  )
}
