import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextWithTitle({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section className="sm:mb-32 mb-10 mt-10" data-component="TextWithTitle">
      <div className="container xl:max-w-screen-xl mx-auto">
        <Content className="children-h2:text-dark/30 mb-8 max-w-xl">{fields.title}</Content>

        <div className="max-w-2xl mx-auto mt-16">
          <Content>{fields.description}</Content>
        </div>
      </div>
    </section>
  )
}
