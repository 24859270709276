import type { IconProps } from './props'

export default function WhatsApp(props: IconProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
      <g fill="#15EE7A" clipPath="url(#clip0_525_160)">
        <path d="M14.55 2.503C11.248-.808 5.865-.838 2.525 2.436A8.38 8.38 0 00.927 12.26l-.902 4.344a.328.328 0 00.4.387l4.284-1.008c4.205 2.074 9.31.375 11.401-3.795a8.38 8.38 0 00-1.566-9.684h.007-.001zM13.21 13.1a6.71 6.71 0 01-7.669 1.245l-.6-.295-2.632.617v-.033L2.864 12l-.293-.573a6.558 6.558 0 011.224-7.665c2.601-2.577 6.814-2.577 9.415 0l.03.04a6.564 6.564 0 01-.035 9.3l.005-.004z"></path>
        <path d="M12.978 11.184c-.323.503-.835 1.118-1.473 1.273-1.128.268-2.845 0-4.994-1.97l-.027-.025C4.614 8.73 4.118 7.287 4.229 6.145c.065-.651.61-1.237 1.07-1.622a.617.617 0 01.96.224l.692 1.552a.6.6 0 01-.074.618l-.352.448a.59.59 0 00-.047.66c.334.488.734.93 1.188 1.31.49.467 1.044.861 1.647 1.17.225.09.483.036.651-.136l.404-.407a.615.615 0 01.616-.17l1.647.468a.612.612 0 01.347.925z"></path>
      </g>
      <defs>
        <clipPath id="clip0_525_160">
          <path fill="#fff" d="M0 0H17V17H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
