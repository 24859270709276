import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Loading from '~/components/elements/Loading'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Form from '~/components/elements/Form'

export default function FormMoreInformation({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section className="sm:mb-20 mb-10 overflow-hidden" data-component="FormMoreInformation">
      <div className="container">
        <div className="lg:grid grid-cols-4 items-start">
          <div className="-mr-36">
            <LossePlaatjie className="xl:mt-36 mt-48 z-10 lg:block hidden relative" src={fields.image} />
          </div>

          <div className="bg-dark 2xl:px-64 2xl:pl-64 2xl:pr-64 xl:pl-64 lg:pl-56 sm:pl-16 pl-6 xl:pr-32 lg:pr-0 sm:pr-16 pr-6 2xl:pt-14 2xl:pb-20 pb-10 pt-8 col-span-3 relative">
            <div className="bg-dark absolute left-full lg:block hidden top-0 w-[100vw] h-full" />

            <Content className="text-white/40 sm:mb-10 mb-6">{fields.description}</Content>

            <Form
              extraTypes={{}}
              data={fields.form}
              renderLoading={() => <Loading />}
              renderStatus={({ status, content }) => <Content className="!text-white">{content}</Content>}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
