import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Page_Flexcontent_Flex_Gallery } from '~/graphql/types'
import FsLightbox from 'fslightbox-react'
import { useState } from 'react'

export default function Gallery({ fields }: { fields: Page_Flexcontent_Flex_Gallery }) {
  const [toggler, setToggler] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)

  let count = 0
  let reverse = false
  const grid = fields.images?.map((image, index) => {
    if (count === 2) {
      reverse = !reverse
      count = 0
    }
    count++

    if (reverse) {
      // @ts-ignore
      image.className = index % 2 ? 'col-span-2' : 'col-span-1'
      return image
    }
    // @ts-ignore
    image.className = index % 2 ? 'col-span-1' : 'col-span-2'
    return image
  }) as any[]

  return (
    <section data-component="Gallery" className="container sm:mb-20 mb-10 xl:max-w-screen-xl mx-auto">
      <div className="grid grid-cols-3 sm:gap-4 gap-2">
        <FsLightbox sources={grid?.map((image) => image?.image?.mediaItemUrl)} slide={currentSlide} toggler={toggler} />
        {grid?.map((image, index) => (
          <button
            type="button"
            onClick={() => {
              setCurrentSlide(index + 1)
              setToggler(!toggler)
            }}
            key={image?.image?.mediaItemUrl}
            className={clsx(image.className, 'max-h-[550px]')}
          >
            <LossePlaatjie maxWidth={1000} className="object-cover w-full xl:h-[550px] sm:h-[300px] h-[175px]" src={image?.image} />
          </button>
        ))}
      </div>
    </section>
  )
}
