import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextCallToAction({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section
      className={clsx('bg-primary sm:py-10 py-8 text-white', fields.connectToBlock && 'sm:-mt-20 -mt-10')}
      data-component="TextCallToAction"
    >
      <div className="container lg:max-w-screen-lg mx-auto lg:grid flex flex-col sm:gap-y-8 gap-y-4 lg:text-left text-center grid-cols-2">
        <Content>{fields.title}</Content>

        <div className="flex items-center justify-center">
          <LosseLink className="button-transparent mx-auto" src={fields.callToAction} />
        </div>
      </div>
    </section>
  )
}
