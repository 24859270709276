import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import TextDefault from './TextDefault'
import TextThreeColumns from './TextThreeColumns'
import TextAdvisor from './TextAdvisor'
import TextTwoColumns from './TextTwoColumns'
import TextCallToAction from './TextCallToAction'
import TextConfigurator from './TextConfigurator'
import TextReviews from './TextReviews'
import TextWithTitle from './TextWithTitle'
import TextFaq from './TextFaq'

export default function TextShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: TextDefault,
    three_columns: TextThreeColumns,
    advisor: TextAdvisor,
    two_columns: TextTwoColumns,
    cta: TextCallToAction,
    configurator: TextConfigurator,
    reviews: TextReviews,
    with_title: TextWithTitle,
    faq: TextFaq
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
