import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextTwoColumns({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section className="container mx-auto xl:max-w-screen-xl sm:mb-20 mb-10" data-component="TextTwoColumns">
      <div className="lg:grid flex flex-col gap-y-8 xl:grid-cols-3 lg:grid-cols-2 xl:gap-x-10 gap-x-6">
        <Content className="text-dark/30 pr-8">{fields.title}</Content>

        <Content className="xl:col-span-2 xl:pr-32">{fields.description}</Content>
      </div>
    </section>
  )
}
