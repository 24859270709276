import { useLoaderData } from '@ubo/losse-sjedel'
import { Fragment } from 'react'
import type { LoaderData } from '@ubo/losse-sjedel'
import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Post } from '~/graphql/types'

interface Crumb {
  pathname: string
  label: string
}

function addBreadcrumbBasedOnPostType(postType: string): Crumb | undefined {
  // if (postType === 'service') {
  //   return {
  //     pathname: '/service-maintenance/',
  //     label: 'Services'
  //   }
  // }

  // if (postType === 'market') {
  //   return {
  //     pathname: '/markets/',
  //     label: 'Markets'
  //   }
  // }

  // if (postType === 'location') {
  //   return {
  //     pathname: '/about-us/locations/',
  //     label: 'Locations'
  //   }
  // }

  // if (postType === 'knowledge') {
  //   return {
  //     pathname: '/knowledge/',
  //     label: 'Knowledge base'
  //   }
  // }

  // if (postType === 'product') {
  //   return {
  //     pathname: '/products/',
  //     label: 'Products'
  //   }
  // }

  // if (postType === 'reference') {
  //   return {
  //     pathname: '/references/',
  //     label: 'References'
  //   }
  // }

  // if (postType === 'exhibition') {
  //   return {
  //     pathname: '/about-us/exhibitions/',
  //     label: 'Exhibitions'
  //   }
  // }

  if (postType === 'project') {
    return {
      pathname: '/projecten/',
      label: 'Projecten'
    }
  }

  if (postType === 'assortiment') {
    return {
      pathname: '/assortiment/',
      label: 'Assortiment'
    }
  }
}

function addBreadcrumbBasedOnParent(parentNode: Post) {
  if (!parentNode.uri) {
    return
  }
  if (!parentNode.title) {
    return
  }

  return {
    pathname: parentNode.uri,
    label: parentNode.title
  }
}

interface BreadcrumbsProps {
  title?: string
  className?: string
}

export default function Breadcrumbs({ title, className }: BreadcrumbsProps) {
  const data = useLoaderData<LoaderData>()

  const additionalBreadcrumbs: Crumb[] = []

  if (data.page?.contentType?.node?.name) {
    const crumb = addBreadcrumbBasedOnPostType(data.page.contentType.node.name)
    if (crumb) additionalBreadcrumbs.push(crumb)
    const parentCrumb = data.page.parent ? addBreadcrumbBasedOnParent(data.page.parent.node as Post) : null
    if (parentCrumb) additionalBreadcrumbs.push(parentCrumb)
  }

  const items: Crumb[] = [
    { pathname: '/', label: 'Home' },
    ...additionalBreadcrumbs,
    { pathname: '', label: title || data.page.title || '' }
  ]

  return (
    <div className={clsx('flex items-center flex-wrap', className)}>
      {items.map((crumb, index) => (
        <Fragment key={crumb.pathname}>
          <LosseLink
            to={crumb.pathname}
            className={clsx(index !== items.length - 1 && 'text-white/60', !!crumb.pathname && 'hover:underline focus:opacity-50')}
          >
            {crumb.label}
          </LosseLink>
          {items.length !== index + 1 && <Separator />}
        </Fragment>
      ))}
    </div>
  )
}

function Separator() {
  return (
    <div className={clsx('mx-3 h-2 w-2')}>
      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
        <path
          d="M6 5.5C6.00037 5.71535 5.95805 5.92865 5.87545 6.12764C5.79286 6.32664 5.67162 6.5074 5.51871 6.65953L1.16331 11L-5.06849e-08 9.84047L4.35458 5.5L-4.3014e-07 1.15954L1.16331 -5.08498e-08L5.51789 4.34047C5.67095 4.49254 5.79234 4.67327 5.87507 4.87226C5.95781 5.07126 6.00027 5.28459 6 5.5Z"
          fill="currentColor"
        />
      </svg>
    </div>
  )
}
