import { Link, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Vacature } from '~/graphql/types'
import ArrowRight from '~/components/elements/Icons/ArrowRight'

export default function VacancyItem({ vacancy }: { vacancy: Vacature }) {
  return (
    <Link to={vacancy.uri || ''} className="relative" data-component="ProjectItem">
      <LossePlaatjie className="aspect-square object-cover" maxWidth={1000} src={vacancy.featuredImage?.node} />
      <div
        className="bg-dark/40 w-full h-full absolute left-0 top-0 text-white p-8 flex flex-col justify-end"
      >
        <div className="flex items-center justify-between">
          <Content className="text-xl">{vacancy.title}</Content>
          <div
            className="relative"
          >
            <ArrowRight />
          </div>
        </div>
      </div>
    </Link>
  )
}
