import type { IconProps } from './props'

export default function Mail(props: IconProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
      <g clipPath="url(#clip0_525_153)">
        <path
          fill="currentColor"
          d="M14.875.708H2.125A2.125 2.125 0 000 2.833v13.459h17V2.833A2.125 2.125 0 0014.875.709zM2.125 2.125h12.75a.708.708 0 01.708.708v.473l-5.58 5.58a2.13 2.13 0 01-3.006 0l-5.58-5.58v-.473a.708.708 0 01.708-.708zm-.708 12.75V5.313l4.578 4.576a3.547 3.547 0 005.01 0l4.578-4.576v9.562H1.417z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_525_153">
          <path fill="currentColor" d="M0 0H17V17H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
