import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextDefault({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section className="sm:mb-20 mb-10" data-component="TextDefault">
      <div className="container py-5">
        <Content className="ul-with-square-small">{fields?.description}</Content>
      </div>
    </section>
  )
}
