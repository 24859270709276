import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Assortiment } from '~/graphql/types'
import Content from '../elements/Content'
import S from '../elements/Icons/S'

export default function Assortiment({ fields }: { fields: Page_Flexcontent_Flex_Assortiment }) {
  return (
    <section className="sm:mb-20 mb-10" data-component="Assortiment">
      <div className="bg-dark text-white">
        <div className="container max-w-screen-2xl relative">
          {!fields.simpleView ? (
            <>
              <S className="absolute right-0 top-0 lg:w-[500px] sm:w-[350px] w-[200px] h-auto" />
              <div className="xl:w-[45%] lg:w-[50%] sm:w-[80%] w-full flex flex-col xl:pl-24 sm:py-20 py-10">
                <Content className="mb-8 children-h2:text-white/30">{fields.title}</Content>
                <div className="xl:pl-24 sm:pl-10 mb-14">
                  <Content className="mb-4 sm:text-lg">{fields.description}</Content>
                  <LosseLink className="with-line text-lg" src={fields.callToAction} />
                </div>
              </div>
            </>
          ) : (
            <div className="md:py-20 sm:py-14 py-8">
              <Content className="mb-14 children-h2:text-white/30">{fields.title}</Content>
            </div>
          )}
        </div>
      </div>

      <div className="container -mt-14 z-10 relative">
        <div className="grid lg:grid-cols-4 grid-cols-2 gap-y-8 gap-x-8">
          {fields.types?.map((type) => (
            <div key={type?.title}>
              <LossePlaatjie className="lg:max-h-full max-h-[300px] object-cover" src={type?.image} maxWidth={400} />
              <div className="sm:px-4 pt-4">
                <Content className="xl:with-line font-bold sm:text-xl text-base sm:mb-6 mb-2">{type?.title}</Content>

                <ul className="flex flex-col gap-y-3 xl:pl-6">
                  {type?.links?.map((item) => (
                    <li key={item?.link?.title}>
                      <LosseLink className="xl:text-lg sm:text-base text-sm hover:ml-2 transition-all" to={item?.link?.url}>
                        <b>{'> '}</b>
                        {item?.link?.title}
                      </LosseLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
