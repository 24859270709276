import { LosseLink, losseLoader, useFetcher } from '@ubo/losse-sjedel'
import { useEffect } from 'react'
import { FreeMode } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { InstagramFeedItem, Page_Flexcontent_Flex_InstagramFeed } from '~/graphql/types'
import Content from '../elements/Content'
import Instagram from '../elements/Icons/Instagram'

export default function InstagramFeed({ fields }: { fields: Page_Flexcontent_Flex_InstagramFeed }) {
  const fetcher = useFetcher<{ instagramFeed: InstagramFeedItem[] }>()

  useEffect(() => {
    fetcher.load(
      losseLoader({
        name: 'InstagramFeed'
      })
    )
  }, [])

  return (
    <section data-component="InstagramFeed" className="sm:mb-20 mb-10 container">
      <Content className="children-h2:text-black/30">{fields.title}</Content>
      <LosseLink className="with-line !font-normal mb-14" to={fields.instagramLink?.url}>
        <Instagram className="mr-3" />
        {fields.instagramLink?.title}
      </LosseLink>

      <Swiper spaceBetween={15} modules={[FreeMode]} freeMode slidesPerView={'auto'}>
        {fetcher.data?.instagramFeed?.map((item) => (
          <SwiperSlide className="!w-[200px] !h-[200px]" key={item.id}>
            <a
              className="relative text-white block w-[200px] h-[200px] hover:scale-[1.05] transition-transform"
              href={item.permalink || ''}
              target="_blank"
              rel="noreferrer"
              title={item.caption || ''}
            >
              {item.mediaType === 'CAROUSEL_ALBUM' && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="absolute right-2 top-2 w-6 h-6"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2L2 7 12 12 22 7 12 2z"></path>
                  <path d="M2 17L12 22 22 17"></path>
                  <path d="M2 12L12 17 22 12"></path>
                </svg>
              )}
              <img className="w-full h-full object-cover" src={item.thumbnailUrl || item.mediaUrl || ''} alt={item.caption || ''} />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}
