import type { IconProps } from './props'

export default function S(props: IconProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="645" height="568" fill="none" viewBox="0 0 645 568">
      <g clipPath="url(#clip0_546_112)" opacity="0.05">
        <path
          fill="#fff"
          d="M636.459 346.155c-5.693-16.132-13.42-30.366-23.18-42.77-9.761-12.404-20.945-23.045-33.552-31.857-12.607-8.879-25.892-16.199-39.855-21.96-13.963-5.83-27.993-10.1-42.092-12.879-14.098-2.779-27.451-4.27-40.058-4.541H187.684c-22.232 0-39.516-5.761-51.987-17.216-12.472-11.523-18.708-27.112-18.708-46.904 0-19.792 6.236-35.246 18.708-47.108 12.471-11.861 29.755-17.826 51.987-17.826h398.347V0H187.684c-18.911 0-39.313 2.982-61.206 8.88-21.893 5.964-42.227 15.589-61.002 28.941C46.7 51.175 31.11 68.459 18.639 89.742 6.168 111.024-.068 137.052-.068 167.96c0 30.908 6.236 57.003 18.707 78.422 12.472 21.351 28.062 38.702 46.837 51.852 18.775 13.217 39.109 22.774 61.002 28.739 21.893 5.964 42.295 8.947 61.206 8.947h269.157c10.777 0 20.605 1.559 29.484 4.744 8.88 3.186 16.336 7.66 22.504 13.489 6.168 5.829 10.845 12.675 14.166 20.605 3.321 7.93 4.948 16.606 4.948 26.163 0 19.521-6.236 35.111-18.708 46.769-12.471 11.59-29.959 17.419-52.462 17.419H5.423v102.959H456.84c12.607 0 26.028-1.288 40.262-3.931 14.233-2.644 28.264-6.711 42.091-12.269 13.828-5.558 27.18-12.675 40.059-21.418 12.878-8.676 24.13-19.25 33.754-31.654 9.625-12.404 17.352-26.705 23.181-42.973 5.829-16.267 8.812-34.5 8.812-54.834 0-20.334-2.847-38.567-8.541-54.631"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_546_112">
          <path fill="#fff" d="M0 0H645V568H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
