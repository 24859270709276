import type { IconProps } from './props'

export default function Phone(props: IconProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g clipPath="url(#clip0_514_118)">
        <path
          fill="currentColor"
          d="M13 1a1 1 0 011-1 10.011 10.011 0 0110 10 1 1 0 11-2 0 8.009 8.009 0 00-8-8 1 1 0 01-1-1zm1 5a4 4 0 014 4 1 1 0 002 0 6.006 6.006 0 00-6-6 1 1 0 000 2zm9.093 10.74a3.1 3.1 0 010 4.377l-.91 1.05c-8.19 7.84-28.12-12.085-20.4-20.3l1.15-1a3.081 3.081 0 014.327.04c.03.03 1.884 2.437 1.884 2.437a3.1 3.1 0 01-.007 4.282L7.979 9.082a12.781 12.781 0 006.93 6.945l1.466-1.165a3.1 3.1 0 014.28-.006s2.407 1.852 2.438 1.883zm-1.376 1.453s-2.393-1.84-2.424-1.872a1.1 1.1 0 00-1.55 0c-.026.028-2.043 1.635-2.043 1.635a1 1 0 01-.98.152A15.01 15.01 0 015.9 9.3a1 1 0 01.145-1s1.607-2.018 1.634-2.044a1.1 1.1 0 000-1.549 503.94 503.94 0 01-1.872-2.425 1.1 1.1 0 00-1.51.04l-1.15 1C-2.495 10.104 14.776 26.417 20.72 20.8l.91-1.05a1.12 1.12 0 00.086-1.557z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_514_118">
          <path fill="currentColor" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
